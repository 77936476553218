/*
Theme Name: Feuerwehr Michelhausen 5.5.a
Author: Martin Klestil
Author URI: http://www.ff-michelhausen.at/
Description: Theme Feuerwehr Michelhausen 5.5.a
Version: 5.5.a
Date: 2023-09-05



*/
/*
0-600px:    Phone
600-900px:  Tablet portrait
900-1200px: Tablet landscape
(1200-1800px): is where our normal styles apply
1800px + :  Big desktop
*/
/*
Breakpoints argument choices:
- phone
- tabe-port
- tab-land
- big-desktop
*/
/*Social Media*/
.fa {
  padding: 20px;
  font-size: 30px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px; }
  .fa:hover {
    opacity: 0.7; }
  .fa-facebook {
    background: #3B5998;
    color: white; }
  .fa-twitter {
    background: #55ACEE;
    color: white; }
  .fa-instagram {
    background: #cd486b;
    color: white; }
  .fa-google {
    background: #dd4b39;
    color: white; }
  .fa-xing {
    background: #126567;
    color: white; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  padding: 3rem;
  background-color: white;
  max-width: 240rem;
  margin: auto; }
  @media (max-width: 56.25em) {
    body {
      padding: 0;
      background-color: #f7f7f7; } }
  @media (max-width: 75em) {
    body {
      padding: 0;
      background-color: #f7f7f7; } }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #000; }

h1, h2, h3, h4, h5 {
  font-family: 'Raleway', sans-serif;
  overflow-wrap: break-word;
  width: 100%; }

a {
  color: #B22222;
  font-size: 1.4rem; }

p a strong {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

p strong a {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.heading-primary, h1 {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem; }
  .heading-primary a, .heading-primary :active, h1 a, h1 :active {
    text-decoration: none;
    background-image: linear-gradient(to right, #d62c2c, #881919);
    -webkit-background-clip: text;
    color: transparent; }
  .heading-primary--main, h1--main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;
    text-shadow: -1px 2px 1px #858585;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out; }
    @media (max-width: 37.5em) {
      .heading-primary--main, h1--main {
        letter-spacing: 1rem;
        font-size: 5rem; } }
  .heading-primary--sub, h1--sub {
    display: block;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 17.4px;
    text-shadow: -1px 2px 1px #858585;
    animation-name: moveInRight;
    animation-duration: 1s;
    animation-timing-function: ease-out; }
    @media (max-width: 37.5em) {
      .heading-primary--sub, h1--sub {
        letter-spacing: .5rem; } }

.heading-secondary, h2 {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #d62c2c, #881919);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all .2s; }
  @media (max-width: 56.25em) {
    .heading-secondary, h2 {
      font-size: 3rem; } }
  @media (max-width: 56.25em) {
    .heading-secondary, h2 {
      font-size: 2.5rem; } }
  .heading-secondary a, .heading-secondary :active, h2 a, h2 :active {
    font-size: 3.5rem;
    text-decoration: none;
    background-image: linear-gradient(to right, #d62c2c, #881919);
    -webkit-background-clip: text;
    color: transparent; }

.heading-tertiary, h3 {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem; }
  .heading-tertiary a, .heading-tertiary :active, h3 a, h3 :active {
    font-size: 1.7rem;
    text-decoration: none;
    background-image: linear-gradient(to right, #d62c2c, #881919);
    -webkit-background-clip: text;
    color: transparent; }

.paragraph, p {
  font-size: 1.5rem; }
  .paragraph:not(:last-child), p:not(:last-child) {
    margin-bottom: 3rem; }
  .paragraph a, p a {
    font-size: 1.5rem; }

ul, li {
  font-size: 1.5rem;
  margin-left: 1rem; }
  ul:last-child, li:last-child {
    margin-bottom: 3rem; }

.wp-block-cover__inner-container p,
.wp-block-cover__inner-container a {
  font-size: 4rem; }
  @media (max-width: 56.25em) {
    .wp-block-cover__inner-container p,
    .wp-block-cover__inner-container a {
      font-size: 2.5rem; } }

.u-center-text {
  text-align: center !important; }

.u-margin-bottom-bigger {
  margin-bottom: 12rem !important; }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }
  @media (max-width: 56.25em) {
    .u-margin-bottom-big {
      margin-bottom: 5rem !important; } }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }
  @media (max-width: 56.25em) {
    .u-margin-bottom-medium {
      margin-bottom: 3rem !important; } }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-top-small {
  margin-top: 1.5rem !important; }

.u-margin-top-big {
  margin-top: 8rem !important; }

.u-margin-top-huge {
  margin-top: 10rem !important; }

.section-archiv {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  grid-column-gap: 1rem;
  padding: 2rem;
  margin: 1rem;
  background-color: #ffffff6e; }

.section-news {
  padding: 2rem; }

.section-features {
  padding: 13rem 0;
  background-image: linear-gradient(to right bottom, rgba(214, 44, 44, 0.8), rgba(136, 25, 25, 0.8)), url(../FF-Michelhausen/web_images/Einsatz3.jpg);
  background-size: cover;
  -webkit-clip-path: polygon(0 5%, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 5%, 100% 0, 100% 85%, 0 100%);
  transform: skewY(0deg); }
  @media (max-width: 56.25em) {
    .section-features {
      padding: 10rem 0;
      background-image: linear-gradient(to right bottom, rgba(214, 44, 44, 0.8), rgba(136, 25, 25, 0.8)), url(../FF-Michelhausen/web_images/Einsatz3-small.jpg);
      background-size: cover;
      -webkit-clip-path: polygon(0 2%, 100% 0, 100% 98%, 0 100%);
      clip-path: polygon(0 2%, 100% 0, 100% 98%, 0 100%); } }
  .section-features * {
    transform: skewY(0deg); }

.section-jugend {
  padding: 2rem; }

.section-frontpage {
  padding: 2rem; }

.site {
  background-color: #f7f7f7;
  padding: 10vh 0rem 5rem 0rem;
  font-size: 1.6rem; }
  .site-content a {
    color: #B22222; }
  .site-news {
    margin-top: 0; }
  .site-jugend__list {
    font-weight: 700;
    font-size: 1.6rem; }
    .site-jugend__listli:last-child {
      margin-bottom: 3rem; }
    .site-jugend__list-item {
      font-size: 1.5rem; }
  .site-single {
    overflow: hidden; }
  .site img {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-position: top; }

article {
  padding: 0 2% 0 2%; }

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  font-size: 1.6rem;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -30%);
  border: none;
  cursor: pointer; }

.btn:hover {
  transform: translate(-50%, -30%) translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.btn:active, .btn:focus {
  outline: none;
  transform: translate(-50%, -30%) translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn--white {
  background-color: #fff;
  color: #252525; }
  .btn--white::after {
    background-color: #fff; }

.btn--green {
  background-color: #B22222;
  color: #fff; }
  .btn--green::after {
    background-color: #fff; }

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }

.btn--animated {
  animation: moveInBottom .5s ease-out .75s;
  animation-fill-mode: backwards; }

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #B22222;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #B22222;
  padding: 3px;
  transition: all .2s; }

.btn-text:hover {
  background-color: #B22222;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.card {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
  margin: 1rem; }
  @media (max-width: 56.25em) {
    .card {
      height: auto;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); } }
  .card__head {
    height: auto;
    position: relative;
    box-shadow: none;
    transition: all .8s;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden; }
  .card__buttom {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    background-image: linear-gradient(to right bottom, #d62c2c, #881919); }
  .card__picture {
    background-size: cover;
    height: 30rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-image: linear-gradient(to right bottom, #d62c2c, #881919); }
    .card__picture img {
      width: auto;
      min-height: 30rem;
      background-size: cover;
      background-position: center; }
  .card__heading {
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: #fff;
    position: absolute;
    top: 18rem;
    right: 2rem; }
    .card__heading a {
      font-size: 2rem;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      color: #fff;
      text-decoration: none; }
  .card__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background-image: linear-gradient(to right bottom, rgba(214, 44, 44, 0.85), rgba(136, 25, 25, 0.85)); }
  .card__details {
    padding: 1.5rem;
    height: 20rem; }
    .card__details p {
      font-size: 1.5rem;
      margin-bottom: 1rem; }
    .card__details--date {
      text-align: center;
      font-weight: 300;
      padding: 0.5rem; }
    .card__details a {
      text-decoration: none; }
  .card__redBox {
    height: 20rem;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem; }
  .card__info-box {
    display: flex;
    justify-content: space-around; }
    .card__info-box--tags {
      font-size: 1.3rem;
      padding: 0.5rem;
      text-transform: uppercase;
      color: #fff; }
      .card__info-box--tags a,
      .card__info-box--tags a:link,
      .card__info-box--tags a:visited {
        text-decoration: none;
        color: #fff;
        font-size: 1.3rem; }

.composition {
  position: relative; }
  .composition__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: absolute;
    transition: all .2s;
    outline-offset: 2rem; }
    @media (max-width: 56.25em) {
      .composition__photo {
        float: left;
        position: relative;
        width: 33.333%;
        box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2); } }
    .composition__photo--p1 {
      left: 0;
      top: -2rem; }
      @media (max-width: 56.25em) {
        .composition__photo--p1 {
          top: 0;
          transform: scale(1.2); } }
    .composition__photo--p2 {
      right: 0;
      top: 2rem; }
      @media (max-width: 56.25em) {
        .composition__photo--p2 {
          top: -1rem;
          transform: scale(1.3);
          z-index: 100; } }
    .composition__photo--p3 {
      left: 20%;
      top: 10rem; }
      @media (max-width: 56.25em) {
        .composition__photo--p3 {
          left: 0;
          top: 1rem;
          transform: scale(1.1); } }
    .composition__photo:hover {
      outline: 1.5rem solid #B22222;
      transform: scale(1.05) translateY(0.5rem);
      box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
      z-index: 20; }
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95); }

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, max-content));
  grid-column-gap: 4rem;
  padding: 2rem;
  grid-row-gap: 4rem; }
  .features__box {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
    transition: transform .3s; }
    @media (max-width: 56.25em) {
      .features__box {
        padding: 2rem; } }
    .features__box-icon {
      max-height: 10rem;
      max-width: 15rem;
      margin-bottom: .5rem;
      display: inline-block;
      background-image: linear-gradient(to right, #d62c2c, #881919);
      -webkit-background-clip: text;
      color: transparent; }
      @media (max-width: 56.25em) {
        .features__box-icon {
          margin-bottom: 0; } }
    .features__box-link {
      text-decoration: none;
      color: black; }
    .features__box:hover {
      transform: scale(1.05); }
    .features__box img {
      box-shadow: none !important; }

.jugend {
  background-image: linear-gradient(to right bottom, rgba(214, 44, 44, 0.8), rgba(136, 25, 25, 0.8)), url(../FF-Michelhausen/web_images/Feuerwehrhaus.jpg); }

.form__group:not(:last-child) {
  margin-bottom: 2rem; }

.form__input {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 1.5rem 2rem;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all .3s; }
  @media (max-width: 56.25em) {
    .form__input {
      width: 100%; } }
  .form__input:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid #B22222; }
  .form__input:focus:invalid {
    border-bottom: 3px solid #ff7730; }
  .form__input::-webkit-input-placeholder {
    color: #999; }
  .form__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #999; }
  .form__input:-ms-input-placeholder {
    /* IE 10+ */
    color: #999; }
  .form__input:-moz-placeholder {
    /* Firefox 18- */
    color: #999; }

.form__label {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: .7rem;
  display: block;
  transition: all .3s; }

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem); }

.form__radio-group {
  width: 49%;
  display: inline-block; }
  @media (max-width: 56.25em) {
    .form__radio-group {
      width: 100%;
      margin-bottom: 2rem; } }

.form__radio-input {
  display: none; }

.form__radio-label {
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  padding-left: 4.5rem; }

.form__radio-button {
  height: 3rem;
  width: 3rem;
  border: 5px solid #B22222;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -.4rem;
  left: 0; }
  .form__radio-button::after {
    content: "";
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #B22222;
    opacity: 0;
    transition: opacity .2s; }

.form__radio-input:checked ~ .form__radio-label .form__radio-button:after {
  opacity: 1; }

.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  transform: skewX(-12deg); }
  @media (max-width: 56.25em) {
    .story {
      width: 100%;
      padding: 4rem;
      padding-left: 7rem; } }
  @media (max-width: 37.5em) {
    .story {
      transform: skewX(0); } }
  .story__shape {
    width: 15rem;
    height: 15rem;
    float: left;
    position: relative;
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    transform: translateX(-3rem) skewX(12deg);
    overflow: hidden; }
    @media (max-width: 37.5em) {
      .story__shape {
        transform: translateX(-3rem) skewX(0); } }
  .story__img {
    height: 100%;
    transform: translateX(-4rem) scale(1.4);
    backface-visibility: hidden;
    transition: all .5s; }
  .story__text {
    transform: skewX(12deg); }
    @media (max-width: 37.5em) {
      .story__text {
        transform: skewX(0); } }
  .story__caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.7rem;
    text-align: center;
    opacity: 0;
    transition: all .5s; }
  .story:hover .story__caption {
    opacity: 1;
    transform: translate(-50%, -50%);
    backface-visibility: hidden; }
  .story:hover .story__img {
    transform: translateX(-4rem) scale(1);
    filter: blur(3px) brightness(80%); }

.popup {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9000;
  transition: all .3s; }
  @supports (--webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .popup {
      --webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.3); } }
  .popup__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: #B22222;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50% -50%) scale(1); }
    .popup__content::after {
      content: "";
      display: table;
      clear: both; }
  .popup__img {
    width: auto;
    height: 40vw;
    margin: 1rem;
    display: block; }

.page::after {
  content: "";
  display: table;
  clear: both; }

.page__controll {
  background-image: linear-gradient(to right bottom, #d62c2c, #881919);
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
  margin: 5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  .page__controll a {
    color: #fff; }

.wp-block-image img {
  max-height: 350px;
  height: auto;
  width: auto !important;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: top; }

.blocks-gallery-item {
  max-height: 350px;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  overflow: hidden; }
  .blocks-gallery-item img {
    max-height: 350px;
    height: auto;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-position: top; }

.wp-block-media-text__media img {
  height: auto;
  width: auto;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: top; }

.wp-block-search__label {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem; }

.wp-block-search__input {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: #fff;
  border: 1px solid #252525;
  padding: .7rem 2rem;
  border-radius: 100px;
  width: 25rem;
  transition: all .2s; }
  .wp-block-search__input:focus {
    outline: none;
    width: 100%;
    background-color: #de5d5d;
    color: #fff; }

.wp-block-search__button {
  border: 1px solid #252525;
  background-color: #B22222;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 600;
  border-radius: 100px;
  width: 10rem;
  padding: 1rem 1rem;
  cursor: pointer; }
  .wp-block-search__button:focus {
    outline: none;
    background-color: #fff;
    color: #B22222; }
  .wp-block-search__button:active {
    transform: translateY(2px); }
  @media (max-width: 56.25em) {
    .wp-block-search__button {
      width: 100%;
      margin-left: 0px !important;
      margin-top: 1rem; } }

.archiv-title {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  justify-self: center; }

.archiv-img {
  grid-column: 1 / 2; }

.header {
  height: auto;
  position: relative; }
  .header__frontpage {
    height: 95vh;
    background-color: #f7f7f7; }
  .header__hero {
    height: 70vh;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%); }
    @media (max-width: 56.25em) {
      .header__hero {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%); } }
    .header__hero-box {
      height: 100%; }
    .header__hero-image {
      height: 100%;
      background-size: cover;
      background-position: top; }
  .header__logo-box {
    position: absolute;
    top: 4rem;
    left: 2rem;
    height: 3.5rem;
    z-index: 1; }
    @media (max-width: 56.25em) {
      .header__logo-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    .header__logo-box__alternativ {
      position: absolute;
      top: 0%;
      height: 3.5rem;
      z-index: 1;
      visibility: visible;
      display: block; }
      @media (min-width: 56.26em) {
        .header__logo-box__alternativ {
          visibility: hidden;
          display: none; } }
  .header__logo {
    height: 3.5rem; }
  .header__text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }
  .header__info-top {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5vh; }
    @media (max-width: 56.25em) {
      .header__info-top h3 {
        font-size: 1.3rem; } }
  .header__nav {
    background-color: #252525;
    border-top: 0.6rem solid #B22222;
    height: 7rem; }
    @media (max-width: 75em) {
      .header__nav {
        height: 11rem;
        margin-bottom: 2rem; } }
    @media (max-width: 56.25em) {
      .header__nav {
        height: 6rem;
        margin-bottom: 12rem; } }
  .header__menu {
    position: relative; }
    @media (max-width: 56.25em) {
      .header__menu {
        visibility: hidden;
        display: none; } }
    .header__menu-div {
      padding-left: 17rem; }
    .header__menu ul {
      position: absolute;
      top: 0;
      left: 0;
      list-style: none; }
      @media (max-width: 75em) {
        .header__menu ul {
          top: 0;
          left: 20%; } }
    .header__menu li {
      float: left;
      padding-top: 0.3rem; }
      .header__menu li a:link, .header__menu li a:visited {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: 600; }
      .header__menu li a:active, .header__menu li a:hover {
        background-color: #B22222; }
  .header__search {
    float: right !important; }
    @media (max-width: 75em) {
      .header__search {
        visibility: hidden;
        display: none; } }

#notruf {
  text-align: right; }

.row {
  max-width: 114rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 4vh; }
    @media (max-width: 56.25em) {
      .row:not(:last-child) {
        margin-bottom: 3vh; } }
  @media (max-width: 56.25em) {
    .row {
      max-width: 100%;
      /*padding: 0 2%;*/ } }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 6rem; }
      @media (max-width: 56.25em) {
        .row [class^="col-"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 3vh; } }
    @media (max-width: 56.25em) {
      .row [class^="col-"] {
        width: 100% !important; } }
  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2); }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .row .col-2-of-3 {
    width: calc( 2 *((100% - 2 * 6rem) / 3) + 6rem); }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4); }
  .row .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem); }
  .row .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem); }

.footer {
  background-color: #252525;
  padding: 1rem 0;
  font-size: 1.4rem;
  color: #fff;
  border-top: 6px solid #B22222; }
  @media (max-width: 56.25em) {
    .footer {
      padding: 8rem 0; } }
  .footer__logo-box {
    text-align: center;
    margin-bottom: 8rem; }
    @media (max-width: 56.25em) {
      .footer__logo-box {
        margin-bottom: 6rem; } }
  .footer__logo {
    width: 15rem;
    height: auto; }
  .footer__logo {
    width: 15rem;
    height: auto; }
  .footer__list {
    list-style: none; }
    .footer__list .nav-link:link, .footer__list .nav-link:visited {
      color: #f7f7f7;
      background-color: #252525;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      transition: all .2s; }
      @media only screen and (hover: none) {
        .footer__list .nav-link:link, .footer__list .nav-link:visited {
          text-decoration: underline; } }
    .footer__list .nav-link:hover, .footer__list .nav-link:active {
      color: #B22222;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
      transform: scale(1.3); }
  .footer__item {
    display: inline-block; }
    .footer__item:not(:last-child) {
      margin-right: 1.5rem; }
  .footer__navigation {
    border-top: 1px solid #fff;
    padding-top: 2rem;
    display: inline-block; }
    @media (max-width: 56.25em) {
      .footer__navigation {
        width: 100%;
        text-align: center; } }
  .footer__copyright {
    border-top: 1px solid #fff;
    padding-top: 2rem;
    width: 80%;
    float: right; }
    @media (max-width: 56.25em) {
      .footer__copyright {
        width: 100%;
        float: none;
        text-align: center; } }
  .footer__links {
    display: flex;
    justify-content: left; }
    @media (max-width: 56.25em) {
      .footer__links {
        justify-content: center; } }

.navigation__checkbox {
  display: none; }
  @media only screen and (hover: none) {
    .navigation__checkbox {
      border: solid 1px #fff; } }

.navigation__button {
  background-color: #fff;
  height: 7rem;
  width: 7rem;
  position: fixed;
  top: 6rem;
  right: 6rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer; }
  @media (max-width: 56.25em) {
    .navigation__button {
      top: 4rem;
      right: 4rem; } }
  @media (max-width: 37.5em) {
    .navigation__button {
      top: 3rem;
      right: 3rem; } }

.navigation__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background-image: radial-gradient(#d62c2c, #881919);
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1); }
  @media (max-width: 56.25em) {
    .navigation__background {
      top: 4.5rem;
      right: 4.5rem; } }
  @media (max-width: 37.5em) {
    .navigation__background {
      top: 3.5rem;
      right: 3.5rem; } }

.navigation__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .navigation__nav ul {
    opacity: 0;
    width: 0; }

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center; }
  .navigation__list li {
    list-style: none; }

.navigation__item {
  margin: 1rem; }

.navigation a:link, .navigation a:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
  background-size: 220%;
  transition: all .4s; }
  @media (max-width: 56.25em) {
    .navigation a:link, .navigation a:visited {
      font-size: 1.5rem; } }
  @media (max-width: 37.5em) {
    .navigation a:link, .navigation a:visited {
      font-size: 2rem; } }
  .navigation a:link span, .navigation a:visited span {
    margin-right: 1.5rem;
    display: inline-block; }

.navigation a:hover, .navigation a:active {
  background-position: 100%;
  color: #B22222;
  transform: translateX(1rem); }

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80); }

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%; }
  .navigation__checkbox:checked ~ .navigation__nav ul {
    opacity: 1;
    width: 100%; }

.navigation__icon {
  position: relative;
  margin-top: 3rem; }
  .navigation__icon, .navigation__icon::before, .navigation__icon:after {
    width: 3rem;
    height: 2px;
    background-color: #333;
    display: inline-block; }
  .navigation__icon::before, .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all .2s; }
  .navigation__icon::before {
    top: -.8rem; }
  .navigation__icon::after {
    top: .8rem; }

.navigation__button:hover .navigation__icon::before {
  top: -1rem; }

.navigation__button:hover .navigation__icon::after {
  top: 1rem; }

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent; }

.navigation__checkbox:checked + .navigation__button .navigation__icon:before {
  top: 0;
  transform: rotate(135deg); }

.navigation__checkbox:checked + .navigation__button .navigation__icon:after {
  top: 0;
  transform: rotate(-135deg); }

.news {
  display: flex;
  flex-direction: column;
  grid-row-gap: 4rem;
  grid-column-gap: 4rem; }
  .news--articles {
    display: flex;
    width: 100%;
    gap: 2rem;
    justify-content: space-around;
    flex-wrap: wrap; }
    .news--articles > * {
      width: calc(100% / 3.5);
      max-width: 45rem; }
      @media (max-width: 37.5em) {
        .news--articles > * {
          width: 100%; } }
